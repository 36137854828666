import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Button, Alert, Spinner, Row, Col, Image } from 'react-bootstrap';
import { Clipboard } from 'react-bootstrap-icons';
import LUITLogo from './assets/LUIT_logo.png';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import TimerComponent from './TimerComponent';
import { clearLabSessionData } from './sessionUtils';

import Header from './Header';


const DeploymentPage = () => {
    const location = useLocation();
    const [deploying, setDeploying] = useState(false);
    const [deploymentStatus, setDeploymentStatus] = useState({});
    const [validating, setValidating] = useState(false);
    const [validationStatus, setValidationStatus] = useState([]);
    const [polling, setPolling] = useState(false);
    const [pollingStatus, setPollingStatus] = useState(null);
    const [companyWebsite, setCompanyWebsite] = useState(null);
    const [error, setError] = useState(null);
    const [cleanupMessage, setCleanupMessage] = useState(null);
    const [currentTestIndex, setCurrentTestIndex] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [finalSubmissionMessage, setFinalSubmissionMessage] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userSessionId, setUserSessionId] = useState(null);
    const [useCaseContent, setUseCaseContent] = useState('');
    const [resourceNames, setResourceNames] = useState({}); 
    const [architectureImage, setArchitectureImage] = useState('');
    const [showTimer, setShowTimer] = useState(true);
    const [consoleUrl, setConsoleUrl] = useState('');
    const [timerDisplay, setTimerDisplay] = useState('');
    const [loading, setLoading] = useState(true);
    const [endTime, setEndTime] = useState(null);  // Initialize the endTime state
    const [lab, setLab] = useState(location.state.lab || sessionStorage.getItem('lab'));
    const [deploymentTime, setDeploymentTime] = useState(location.state?.estimatedTime || 180); // Set the time from location.state or fallback to default


    const labName = location.state.labName;  // Specific lab name like 'ALB-Challenge' or 's3-static-website'
    const labTitle = location.state.title;
    

    useEffect(() => {
        const idToken = localStorage.getItem("idToken");

        if (idToken) {
            setIsAuthenticated(true);

            const sessionId = localStorage.getItem('userSessionId');
            if (sessionId) {
                setUserSessionId(sessionId);
            } else {
                setError("User session ID is missing. Please log in again.");
            }
        } else {
            setIsAuthenticated(false);
            setError("You are not authenticated. Please log in.");
        }

        axios.get(`${process.env.REACT_APP_S3_USE_CASE_BUCKET}/${labName}.html`)
            .then(response => {
                setUseCaseContent(response.data);
            })
            .catch(error => {
                setUseCaseContent('Error loading use case content.');
            });

        setArchitectureImage(`${process.env.REACT_APP_S3_DIAGRAM_BUCKET}/${labName}.png`);
    }, [labName]);


    useEffect(() => {
        // Check if there's an existing lab session or ongoing polling when the page loads
        const savedLab = sessionStorage.getItem('lab');
        const savedLabName = sessionStorage.getItem('labName');
        const savedStackName = sessionStorage.getItem('stackName');
        const savedPollingSessionId = sessionStorage.getItem('pollingSessionId');
        const savedDeploymentStatus = JSON.parse(sessionStorage.getItem('deploymentStatus'));
        const savedPollingStatus = sessionStorage.getItem('isPolling');
    
        // Restore lab information if found
        if (savedLab && savedLabName && savedDeploymentStatus) {
            setLab(savedLab);
            setDeploymentStatus(savedDeploymentStatus);
            console.log("Restored lab and deploymentStatus from sessionStorage.");
        }
    
        // Restore polling state if polling was in progress
        if (savedPollingStatus === 'true' && savedStackName && savedPollingSessionId) {
            console.log("Restoring polling...");
            startPolling(savedPollingSessionId, savedStackName);
        }
    
    }, []);

    // Effect to watch for changes in deploying or polling
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (deploying || polling) {
                // Show the default browser prompt
                event.preventDefault();
                event.returnValue = 'Leaving this page will cancel your deployment or cause the environment cleanup to fail.'; 
    
            }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [deploying, polling]);   

    useEffect(() => {
        console.log("Restoring session data from location.state:", location.state);
        
        if (location.state && (location.state.consoleUrl || location.state.username || location.state.password)) {
            const { consoleUrl, username, password, companyWebsite, endTime, lab } = location.state;
    
            console.log("Session Data - Console URL:", consoleUrl);
            console.log("Session Data - Username:", username);
            console.log("Session Data - Password:", password);
            console.log("Session Data - Company Website:", companyWebsite);
            console.log("Session Data - End Time:", endTime);
            console.log("Session Data - Lab:", lab);
    
            // No need to clear session storage automatically here
            setConsoleUrl(consoleUrl);
            setCompanyWebsite(companyWebsite);
            sessionStorage.setItem('lab', lab); // Store lab in sessionStorage
            sessionStorage.setItem('labName', labName); // Store labName in sessionStorage
    
            if (endTime) {
                sessionStorage.setItem('endTime', endTime);
            }
    
            setDeploymentStatus((prevStatus) => ({
                ...prevStatus,
                consoleUrl,
                username,
                password,
                companyWebsite,
                lab,
                isComplete: true,
            }));
    
            console.log("Deployment status updated to isComplete: true");
        } else {
            console.log("No valid session data found in location.state for restoration.");
        }
    
        // Restore resource names when navigating back
        const savedResourceNames = JSON.parse(sessionStorage.getItem('resourceNames'));
        if (savedResourceNames) {
            setResourceNames(savedResourceNames); // Restore resourceNames if found in sessionStorage
            console.log("Restored resourceNames from sessionStorage:", savedResourceNames);
        }
    
        const savedConsoleUrl = sessionStorage.getItem('consoleUrl');
        const savedLab = sessionStorage.getItem('lab');
        if (!lab && savedLab) {
            setLab(savedLab); // Set lab if it exists in sessionStorage
        }
    
        if (!location.state?.consoleUrl && savedConsoleUrl) {
            setConsoleUrl(savedConsoleUrl);
            console.log("Restored Console URL from sessionStorage:", savedConsoleUrl);
        }
    
        if (!location.state?.lab && savedLab) {
            setLab(savedLab);  // Restore lab from sessionStorage
            console.log("Restored Lab from sessionStorage:", savedLab);
        }
    }, [location.state]);
    

    useEffect(() => {
        const savedDeploymentStatus = JSON.parse(sessionStorage.getItem('deploymentStatus'));
        const savedValidationStatus = JSON.parse(sessionStorage.getItem('validationStatus'));
        const savedCompanyWebsite = sessionStorage.getItem('companyWebsite');
        const savedEndTime = sessionStorage.getItem('endTime');
        const savedResourceNames = JSON.parse(sessionStorage.getItem('resourceNames'));
        const savedLab = sessionStorage.getItem('lab');  
        const savedLabTitle = sessionStorage.getItem('labTitle');
        const savedUsername = sessionStorage.getItem('username');
        const savedPassword = sessionStorage.getItem('password');
    
        console.log("Restoring session data from sessionStorage...");
    
        // Restore deployment status
        if (savedDeploymentStatus) {
            setDeploymentStatus(savedDeploymentStatus);
            console.log("Restored deploymentStatus:", savedDeploymentStatus);
            console.log("deploymentStatus.isComplete after restoration:", savedDeploymentStatus?.isComplete);
        }
        
        // Restore validation status
        if (savedValidationStatus) {
            setValidationStatus(savedValidationStatus);
            console.log("Restored validationStatus from sessionStorage:", savedValidationStatus);
        }
    
        // Restore company website
        if (savedCompanyWebsite) {
            setCompanyWebsite(savedCompanyWebsite);
            console.log("Restored companyWebsite from sessionStorage:", savedCompanyWebsite);
        }
    
        // Restore resource names
        if (savedResourceNames) {
            setResourceNames(savedResourceNames);
            console.log("Restored resourceNames from sessionStorage:", savedResourceNames);
        } else {
            console.log("No resourceNames found in sessionStorage");
        }
    
        // Restore lab info
        if (savedLab) {
            setLab(savedLab);  // Restore lab
            console.log("Restored lab from sessionStorage:", savedLab);
        }
    
        // Set additional fields in deployment status (if they exist)
        setDeploymentStatus((prevStatus = {}) => ({
            ...prevStatus,
            labTitle: prevStatus.labTitle || savedLabTitle,
            lab: prevStatus.lab || savedLab,
            username: prevStatus.username || savedUsername,
            password: prevStatus.password || savedPassword,
        }));
    
        // Restore endTime (if it exists)
        if (savedEndTime) {
            setEndTime(savedEndTime);  // Now endTime will be stored in state
            console.log("Restored endTime from sessionStorage:", savedEndTime);
        }
    
        // Once everything is restored, set loading to false
        setLoading(false); // Make sure to stop the loading state after restoration is done
    }, []);
    


    useEffect(() => {
        console.log("Current deploymentStatus:", deploymentStatus);

        if (deploymentStatus && Object.keys(deploymentStatus).length) {
            sessionStorage.setItem('deploymentStatus', JSON.stringify(deploymentStatus));
        }
    }, [deploymentStatus]);

    useEffect(() => {
        if (companyWebsite) {
            sessionStorage.setItem('companyWebsite', companyWebsite);
        }
    }, [companyWebsite]);

    useEffect(() => {
        const storedEndTime = sessionStorage.getItem('endTime');
        console.log("Retrieved endTime from sessionStorage:", storedEndTime);  // Add logging here
    
        if (storedEndTime) {
            const interval = setInterval(() => {
                const currentTime = Date.now();  // Log the current time in epoch (ms)
    
                // Parse endTime from sessionStorage as epoch milliseconds
                const endTime = parseInt(storedEndTime, 10);
    
                // Time remaining in seconds
                const timeLeftInSeconds = Math.max((endTime - currentTime) / 1000, 0);
                //console.log("Time left in seconds:", timeLeftInSeconds);  // Add logging for time left
    
                if (timeLeftInSeconds > 0) {
                    const hours = Math.floor(timeLeftInSeconds / 3600);
                    const minutes = Math.floor((timeLeftInSeconds % 3600) / 60);
                    const seconds = Math.floor(timeLeftInSeconds % 60);
    
                    setTimerDisplay(`${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
                } else {
                    clearInterval(interval);
                    setTimerDisplay('Time is up!');
                    if (!finalSubmissionMessage && !submitting && !validating) {
                        handleSubmit();
                    }
                }
            }, 1000);  // Update timer every second
    
            return () => clearInterval(interval);  // Clear the interval on component unmount
        }
    }, [finalSubmissionMessage, submitting, validating, deploymentStatus]);
    
      
    const handleDeploy = async () => {
        const idToken = localStorage.getItem('idToken');
    
        if (!isAuthenticated || !userSessionId) {
            setError("You are not authenticated. Please log in first.");
            return;
        }
    
        setDeploying(true);
        setPolling(false);
        setError(null);
    
        const requestBody = {
            Lab: lab,
            LabName: labName,
            UserSessionId: userSessionId,
            LabTitle: labTitle,
            Action: 'deploy',
        };
    
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_DEPLOYMENT_LAMBDA_API}`,
                requestBody,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`,
                    },
                }
            );
    
            setDeploymentStatus(response.data);
            startPolling(userSessionId, response.data.stackName);
    
            // Immediately store endTime in sessionStorage
            if (response.data.endTime) {
                console.log("Setting endTime after deployment:", response.data.endTime);
                sessionStorage.setItem('endTime', response.data.endTime);
            }
        } catch (error) {
            setError(
                error.response && error.response.data && typeof error.response.data === 'string'
                    ? error.response.data
                    : 'An unexpected error occurred.'
            );
        }
         finally {
            setDeploying(false);
        }
    };
    
    

    const startPolling = (sessionId, stackName) => {
        const messages = [
            'Creating Environment...',  // Ensures the first message
            'Deploying Cloud Resources...',
            'Terraforming the cloud landscape...',
            'Assembling virtual Lego blocks...',
            'Getting the cloud up to our standards...',
            'Getting you ready to Level Up...',
            'Launching into the cloud stratosphere...',
            'Building bridges between servers...',
            'Getting everything perfect for you...',
            'Fine-tuning your cloud lab...',
            'Smoothing out virtual kinks...',
            'Getting coffee and CloudFormation...',
            'In the cloud kitchen, cooking up resources...',
            'Making cloud magic happen, one byte at a time...'
        ];
    
        // Helper function to get a random message, excluding the first one
        const getRandomMessage = () => {
            const randomIndex = Math.floor(Math.random() * (messages.length - 1)) + 1;  // Skip the first message
            return messages[randomIndex];
        };
    
        setPolling(true);
        setPollingStatus(messages[0]);  // Always start with the first message
    
        // Store polling state to allow restoration on page refresh or navigation
        sessionStorage.setItem('isPolling', true); 
        sessionStorage.setItem('stackName', stackName); 
        sessionStorage.setItem('pollingSessionId', sessionId);
    
        console.log("Polling started...");
    
        let pollCounter = 0;
        const pollInterval = setInterval(async () => {
            try {
                const idToken = localStorage.getItem('idToken');
                console.log(`Polling attempt #${pollCounter + 1}`);
    
                const pollResponse = await axios.get(
                    `${process.env.REACT_APP_POLLING_LAMBDA_API}`,
                    {
                        params: {
                            UserSessionId: sessionId,
                            Lab: lab,
                            LabName: labName,
                            StackName: stackName
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${idToken}`
                        },
                    }
                );
    
                const status = pollResponse.data;
                pollCounter++;
                console.log("Polling response received:", status);
    
                // Every two polling attempts, change the status message (starting after the first two attempts)
                if (pollCounter % 2 === 0) {
                    setPollingStatus(getRandomMessage());
                }
    
                if (status.stackStatus === 'CREATE_COMPLETE') {
                    clearInterval(pollInterval);
                    setPolling(false);
                    sessionStorage.removeItem('isPolling');
                    setPollingStatus('Deployment complete. Your environment is ready.');
                    setDeploymentStatus((prevStatus) => ({
                        ...prevStatus,
                        isComplete: true
                    }));
    
                    if (status.endTime) {
                        console.log("Setting endTime:", status.endTime);
                        sessionStorage.setItem('endTime', status.endTime);
                    }
    
                    if (status.outputs) {
                        const websiteOutput = status.outputs.find(output => output.OutputKey === 'companyWebsite');
                        if (websiteOutput) {
                            setCompanyWebsite(websiteOutput.OutputValue);
                        }
                    }
    
                    if (status.resourceNames) {
                        setResourceNames(status.resourceNames);
                        sessionStorage.setItem('resourceNames', JSON.stringify(status.resourceNames));
                    }
    
                } else if (status.stackStatus === 'CREATE_FAILED') {
                    clearInterval(pollInterval);
                    setPolling(false);
                    setPollingStatus('Deployment failed. Cleaning current lab environment...');
                    sessionStorage.removeItem('isPolling');
                    await handleCleanup();
                }
            } catch (error) {
                setPollingStatus('Error during polling. Please notify support.');
                clearInterval(pollInterval);
                sessionStorage.removeItem('isPolling');
                await handleCleanup();
            }
        }, 15000);  // Polling interval remains at 15 seconds
    };

    const handleValidate = async (testIndex = 0, isSubmit = false) => {
        if (!isSubmit) setValidating(true);
        setError(null);
    
        // Clear previous validation results at the start of a new validation round
        if (testIndex === 0) {
            setValidationStatus([]); // Clear state
            sessionStorage.removeItem('validationStatus'); // Clear session storage
        }
    
        const requestBody = {
            Lab: lab,
            LabName: labName,
            UserSessionId: userSessionId,
            CurrentTestIndex: testIndex,
        };
    
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_VALIDATION_LAMBDA_API}`,
                requestBody,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
                    },
                }
            );
    
            const validationDescription = lab === 'assessment'
                ? response.data.description
                : `Validation ${testIndex + 1}`;
    
            // Add current validation result to state and session storage
            setValidationStatus((prevStatus) => {
                const updatedStatus = [
                    ...prevStatus,
                    {
                        test: response.data.current_test,
                        result: response.data.validation_result.includes('Passed'),
                        description: validationDescription,
                    },
                ];
                sessionStorage.setItem('validationStatus', JSON.stringify(updatedStatus));
                return updatedStatus;
            });
    
            // Recursive call for the next validation test
            if (response.data.next_test_index !== null) {
                await handleValidate(response.data.next_test_index, isSubmit);
            } else if (isSubmit) {
                console.log("All validations completed.");
            }
        } catch (error) {
            console.error("Error during validation:", error);
            setError("Validation failed. Please try again.");
        } finally {
            if (!isSubmit) setValidating(false);
        }
    };
    
    
    // UseEffect for logging state changes
    useEffect(() => {
        console.log("Validation status updated:", validationStatus);
    }, [validationStatus]);
    
    // SessionStorage updates for validationStatus
    useEffect(() => {
        sessionStorage.setItem('validationStatus', JSON.stringify(validationStatus));
        console.log("Saved validationStatus to sessionStorage:", validationStatus);
    }, [validationStatus]);    

    const handleCompleteLabWithTracking = async () => {
        const idToken = localStorage.getItem("idToken");
        const userId = localStorage.getItem('userSessionId'); // UserId is the same as userSessionId
    
        // Retrieve the final validation result from localStorage
        const finalValidationResult = localStorage.getItem('finalValidationResult') === 'Pass';
    
        console.log("Final validation result retrieved from localStorage:", finalValidationResult);
    
        try {
            // Track Lab Completion API Call with the final validation result
            await axios.post(process.env.REACT_APP_TRACK_LAB_COMPLETION_API, {
                UserId: userId,
                LabName: labName,
                Action: 'complete_lab',
                Passed: finalValidationResult // Use finalValidationResult for the Passed field
            }, {
                headers: {
                    'Authorization': `Bearer ${idToken}`
                }
            });
    
            console.log("Lab completion tracked successfully with passed status:", finalValidationResult);
        } catch (error) {
            console.error("Error tracking lab completion:", error);
            setError("Error tracking lab completion.");
        }
    };
    
    
    

    const handleCleanup = async () => {
        setCleanupMessage('');
        console.log("Starting cleanup process...");
    
        // Track lab completion before cleanup
        await handleCompleteLabWithTracking();
    
        const requestBody = {
            Lab: lab,
            LabName: labName,
            UserSessionId: userSessionId,
        };
    
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_CLEANUP_LAMBDA_API}`,
                requestBody,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
                    },
                }
            );
    
            if (response.status === 200 || response.status === 202) {
                console.log("Cleanup successful.");
                updateFinalSubmissionMessage();
            } else {
                throw new Error("Unexpected response during cleanup.");
            }
        } catch (error) {
            console.error("Error during cleanup:", error);
            setError("An error occurred during cleanup. Please try again.");
        }
    };
    

    useEffect(() => {
        const handleUnload = () => {
            if (deploying || polling) {
                // Trigger cleanup only when the user is actually leaving the page.
                handleCleanup();
            }
        };
    
        window.addEventListener('unload', handleUnload);
    
        return () => {
            window.removeEventListener('unload', handleUnload);
        };
    }, [deploying, polling, handleCleanup]);

    const updateFinalSubmissionMessage = () => {
        // Retrieve the final validation result from local storage
        const finalResult = localStorage.getItem('finalValidationResult');
    
        console.log("Retrieved final validation result:", finalResult);
    
        // Update the final submission message based on the result
        if (finalResult === 'Pass') {
            setFinalSubmissionMessage("Lab session has been completed successfully!");
        } else {
            setFinalSubmissionMessage("Lab session has been completed, but not all validations passed.");
        }
    
        // Clear remaining session data
        clearLabSessionData();
        setDeploymentStatus(null);
        setSubmitting(false);
        setValidating(false);
    };
    

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Copied to clipboard!');
        });
    };

    const handleSubmit = async () => {
        if (submitting) return;
    
        setSubmitting(true);
        setError(null);
    
        console.log("Submit button clicked. Starting validation...");
    
        try {
            // Perform validation process
            await handleValidate(0, true);
    
            console.log("All validations completed. Checking results...");
    
            // Ensure the final validation result is determined after all validations are processed
            const finalValidationStatus = JSON.parse(sessionStorage.getItem('validationStatus')) || [];
            const allTestsPassed = finalValidationStatus.every((status) => status.result === true);
            const finalResult = allTestsPassed ? 'Pass' : 'Fail';
    
            // Save the final validation result to local storage
            localStorage.setItem('finalValidationResult', finalResult);
    
            console.log(`Final validation result saved: ${finalResult}`);
    
            // Proceed with cleanup
            await handleCleanup();
    
            console.log("Submission and cleanup process completed.");
        } catch (error) {
            console.error("Error during submission process:", error);
            setError("An error occurred during submission. Please try again.");
        } finally {
            setSubmitting(false);
        }
    };


    const toggleTimer = () => {
        setShowTimer(!showTimer);
    };

    const renderNamingConventions = () => {
        // Check if it's an assessment lab
        if (lab === 'assessment') {
            return (
                <Alert style={{ backgroundColor: '#F4F4F9', borderColor: '#E50A7A', color: '#1A1B54' }} variant="info">
                    <h4>Required Resource Names for Validation Checks</h4>
                    {Object.keys(resourceNames).length > 0 ? (
                        // Show the actual resource names once populated
                        <ul>
                            {Object.keys(resourceNames).map((key) => (
                                <li key={key}><strong>{key}:</strong> {resourceNames[key]}</li>
                            ))}
                        </ul>
                    ) : (
                        // Placeholder message before resource names are available
                        <p>The resource names that need to be used will be populated after the lab is deployed.</p>
                    )}
                </Alert>
            );
        }
        return null;  // Do not show anything if it's not an assessment lab
    };
    
    // Conditional rendering to show loading spinner while restoring session data
    if (loading) {
        return <Spinner animation="border" role="status">Loading...</Spinner>;  // Display spinner during loading
    }

    return (
        <>
        <Header />

        <Container style={{ marginTop: '5rem', background: 'linear-gradient(135deg, #ffffff, #f4f4f9)', padding: '2rem', borderRadius: '8px' }}>

            <h2 style={{ color: '#1A1B54', textAlign: 'center', marginBottom: '2rem' }}>{`${labTitle} Deployment`}</h2>

            {error && (
                <Alert variant="danger">
                    <p>Error: {typeof error === 'string' ? error : JSON.stringify(error)}</p>
                </Alert>
            )}


            {!polling && (!deploymentStatus || !deploymentStatus.isComplete) && !finalSubmissionMessage ? (
                <>
                    {/* Log deploymentStatus and deploymentStatus.isComplete to debug UI rendering */}
                    {console.log("Rendering Start Challenge button. deploymentStatus:", deploymentStatus)}
                    {console.log("deploymentStatus.isComplete:", deploymentStatus?.isComplete)}
                    
                    <Button onClick={handleDeploy} disabled={!isAuthenticated || deploying}
                            style={{ backgroundColor: '#E50A7A', borderColor: '#E50A7A', width: '50%', margin: '0 auto', display: 'block' }}>
                        {deploying ? <Spinner animation="border" size="sm" /> : 'Start'}
                    </Button>
                </>
            ) : null}

            {polling && (
                <div style={{
                    backgroundColor: '#FFF7E6',  // Light background (soft beige)
                    border: '2px solid #E50A7A',  // Border with pink brand color
                    padding: '20px',  // Increased padding for breathing room
                    borderRadius: '12px',  // Softer rounded corners
                    textAlign: 'center',  // Center align the content
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',  // Subtle shadow for depth
                    color: '#1A1B54'  // Text color matching branding
                }}>
                    <h4 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '15px' }}>
                        {pollingStatus}
                    </h4>

                    <TimerComponent 
                        estimatedTime={deploymentTime}  // Deployment time countdown
                        onComplete={() => {
                            console.log('Polling timer finished.');
                        }}
                    />
                    <p style={{
                        marginTop: '10px', 
                        fontSize: '1.1rem',
                        color: '#1A1B54',  // Dark blue for consistency
                    }}>
                        This might take a few minutes. Please do not close this window.
                    </p>
                </div>
            )}


            {deploymentStatus && deploymentStatus.isComplete && !finalSubmissionMessage && (
                <>
                    <Row>
                        <Col md={6}>
                            <Alert style={{ background: 'linear-gradient(135deg, #3a539b, #1A1B54)', borderColor: '#2C3E50', color: '#FFFFFF', padding: '20px', borderRadius: '8px' }}>
                                <h4 style={{ fontSize: '1.5rem', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: '10px' }}>📈</span> Time to Level Up!
                                </h4>
                                <p><strong>Username:</strong> {deploymentStatus.username || 'Username not found'}
                                    <Clipboard
                                        role="button"
                                        onClick={() => handleCopy(deploymentStatus.username || 'Username not found')}
                                        style={{ cursor: 'pointer', marginLeft: '8px', color: '#FFFFFF' }}
                                    />
                                </p>
                                <p><strong>Password:</strong> {deploymentStatus.password || 'Password not found'}
                                    <Clipboard
                                        role="button"
                                        onClick={() => handleCopy(deploymentStatus.password || 'Password not found')}
                                        style={{ cursor: 'pointer', marginLeft: '8px', color: '#FFFFFF' }}
                                    />
                                </p>
                                <p>
                                    <strong>AWS Console URL:</strong>{' '}
                                    <a href={deploymentStatus.consoleUrl || '#'} target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF', textDecoration: 'underline' }}>
                                        {deploymentStatus.consoleUrl || 'URL not available'}
                                    </a>
                                </p>
                                {lab === 'challenge' && companyWebsite && (
                                    <p>
                                        <strong>Website URL:</strong>{' '}
                                        <a href={companyWebsite} target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF', textDecoration: 'underline' }}>
                                            {companyWebsite}
                                        </a>
                                    </p>
                                )}
                            </Alert>

                            <Button
                                onClick={() => handleValidate(0)}
                                disabled={validating || submitting}
                                style={{ backgroundColor: '#E50A7A', borderColor: '#E50A7A', marginRight: '10px' }}
                            >
                                {validating ? <Spinner animation="border" size="sm" /> : 'Validate'}
                            </Button>

                            <Button
                                variant="danger"
                                onClick={handleSubmit}
                                disabled={validating || submitting || !deploymentStatus.isComplete}
                                style={{ backgroundColor: '#E50A7A', borderColor: '#E50A7A', marginRight: '10px' }}
                            >
                                {submitting ? <Spinner animation="border" size="sm" /> : 'End Session'}
                            </Button>

                            <Button onClick={toggleTimer} style={{ backgroundColor: '#F4F4F9', borderColor: '#E50A7A', color: '#1A1B54' }}>
                                {showTimer ? 'Hide Timer' : 'Show Timer'}
                            </Button>

                            {showTimer && timerDisplay && (
                                <Alert style={{ backgroundColor: '#FFF3CD', borderColor: '#FFEEBA', color: '#856404', marginTop: '10px' }} variant="warning">
                                    <h4>
                                        Time Remaining: {timerDisplay}
                                    </h4>
                                </Alert>
                            )}


                        </Col>
                        <Col md={6}>
                            <Alert style={{ backgroundColor: '#F4F4F9', borderColor: '#E50A7A', color: '#1A1B54' }} variant="info">
                                <h4>Validation Results:</h4>
                                {validationStatus.map((status, index) => (
                                    <p key={index}><strong>{status.description}:</strong> {status.result ? 'Passed' : 'Failed'}</p>
                                ))}
                            </Alert>
                        </Col>
                    </Row>
                </>
            )}

            {cleanupMessage && (
                <Alert variant="info" className="mt-4">
                    <h4>{cleanupMessage}</h4>
                </Alert>
            )}

            {finalSubmissionMessage && (
                <div
                    className="final-submission-message my-4 p-4 rounded text-center"
                    style={{
                        backgroundColor: '#fff0f6',
                        border: '2px solid #E50A7A',
                        color: '#1A1B54',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <h3 className="mb-3" style={{ color: '#E50A7A', fontWeight: 'bold' }}>{finalSubmissionMessage}</h3>
                    <div
                        className="validation-results my-3 p-3 rounded"
                        style={{
                            backgroundColor: '#f9f0ff',
                            border: '1px solid #d9d0e6',
                            color: '#722ed1',
                        }}
                    >
                        <h4 style={{ color: '#1A1B54' }}>Validation Results:</h4>
                        {validationStatus.map((status, index) => (
                            <p key={index} style={{ fontSize: '1rem', fontWeight: '500' }}>
                                <strong>{status.description}:</strong>{' '}
                                {status.result ? (
                                    <span style={{ color: '#52c41a' }}>Passed</span>
                                ) : (
                                    <span style={{ color: '#f5222d' }}>Failed</span>
                                )}
                            </p>
                        ))}
                    </div>
                    <Button
                        onClick={() => {
                            sessionStorage.clear(); // Clear all session storage data
                            localStorage.removeItem('finalValidationResult');
                            localStorage.removeItem('currentLabName');
                            setDeploymentStatus(null);
                            setValidationStatus([]);
                            setCompanyWebsite(null);
                            setFinalSubmissionMessage(null);
                            setError(null);
                            window.location.href = '/home';
                        }}
                        style={{
                            backgroundColor: '#E50A7A',
                            borderColor: '#E50A7A',
                            color: '#FFFFFF',
                            width: '50%',
                            marginTop: '1rem',
                            fontSize: '1.2rem',
                            padding: '10px',
                            fontWeight: 'bold',
                        }}
                    >
                        Return to Home Page
                    </Button>
                </div>
            )}


            {!finalSubmissionMessage && (
                <Row className="mt-4">
                    <Col md={6}>
                        <Alert style={{ backgroundColor: '#F4F4F9', borderColor: '#E50A7A', color: '#1A1B54' }} variant="info">
                            <h4>Use Case</h4>
                            <div dangerouslySetInnerHTML={{ __html: useCaseContent }} /> {/* Display the content */}
                        </Alert>
                    </Col>
                    <Col md={6}>
                        {renderNamingConventions()}

                        <Alert style={{ backgroundColor: '#F4F4F9', borderColor: '#E50A7A', color: '#1A1B54' }} variant="info">
                            <h4>Architecture Diagram</h4>
                            <img src={architectureImage} alt="Architecture Diagram" style={{ width: '100%', height: 'auto' }} />
                        </Alert>
                    </Col>
                </Row>
            )}

        </Container>
        </>
    );
};

export default DeploymentPage;