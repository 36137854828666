// components/Header.js

import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import LUITLogo from './assets/LUIT_logo.png'; // Adjust path if necessary
import UserProfile from './UserProfile';

const Header = () => {
  return (
    <Navbar
      expand="lg"
      style={{
        backgroundColor: '#1A1B54',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '0.5rem 1rem',
      }}
    >
      <Container>
        {/* Logo and Title */}
        <Navbar.Brand
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => (window.location.href = '/home')}
        >
          <img
            src={LUITLogo}
            alt="Level Up In Tech Logo"
            style={{ width: '50px', marginRight: '10px' }}
          />
          <span style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '1.5rem' }}>
            Level Up In Tech Labs
          </span>
        </Navbar.Brand>

        {/* User Profile */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <UserProfile />
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
