// UserProfile.js
import React, { useEffect, useState } from 'react';
import { fetchUserProfile, useUserSession } from './sessionUtils'; // Import required functions
import { Dropdown } from 'react-bootstrap'; // Import Dropdown for user profile display
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons CSS

const UserProfile = () => {
  const { idToken, sub } = useUserSession();
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    if (idToken && sub && !userProfile) {
      const getUserProfile = async () => {
        try {
          const profile = await fetchUserProfile(sub, idToken); // Fetch user profile from API
          if (profile) {
            setUserProfile(profile);
          }
        } catch (error) {
          console.error('Error fetching user profile:', error); // Log errors for debugging
        }
      };
      getUserProfile();
    }
  }, [idToken, sub, userProfile]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Dropdown align="end">
        <Dropdown.Toggle
          variant="light"
          id="dropdown-user-profile"
          className="user-profile-toggle"
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'transparent',
            border: 'none',
          }}
        >
          <i
            className="bi bi-person-circle"
            style={{
              fontSize: '1.8rem',
              cursor: 'pointer',
              color: '#FFFFFF',
              marginRight: '0.5rem',
            }}
          ></i> {/* User outline icon */}
          {userProfile && (
            <span style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
              {userProfile.name || 'User'}
            </span>
          )} {/* Display user's name */}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {userProfile ? (
            <>
              <Dropdown.ItemText>
                <strong>Name:</strong> {userProfile.name}
              </Dropdown.ItemText>
              <Dropdown.ItemText>
                <strong>Email:</strong> {userProfile.email}
              </Dropdown.ItemText>
              <Dropdown.ItemText>
                <strong>Role:</strong> {userProfile.role}
              </Dropdown.ItemText>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => {
                  localStorage.removeItem('idToken'); // Remove the user's session token
                  window.location.href = '/login'; // Redirect to login
                }}
              >
                Sign Out
              </Dropdown.Item>
            </>
          ) : (
            <Dropdown.ItemText>Loading profile...</Dropdown.ItemText> // Show while fetching profile
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default UserProfile;
