// sessionUtils.js

// Note: Make sure to import jwtDecode for decoding the ID token
import { jwtDecode } from 'jwt-decode';  // Import jwtDecode to decode the token

export const clearLabSessionData = () => {
    sessionStorage.removeItem('labName');
    sessionStorage.removeItem('lab');
    sessionStorage.removeItem('labTitle');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('password');
    sessionStorage.removeItem('consoleUrl');
    sessionStorage.removeItem('companyWebsite');
    sessionStorage.removeItem('endTime');
    sessionStorage.removeItem('resourceNames');
    sessionStorage.removeItem('deploymentStatus');
    sessionStorage.removeItem('validationStatus');
    sessionStorage.removeItem('isPolling');
    sessionStorage.removeItem('stackName');
    sessionStorage.removeItem('pollingSessionId');  
    console.log("Lab session data cleared.");
};

// Utility function to fetch user profile from API
export const fetchUserProfile = async (username, idToken) => {
    const apiUrl = process.env.REACT_APP_GET_USER_PROFILE_API; // Get the API URL from the environment
    try {
        const response = await fetch(`${apiUrl}?username=${username}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            if (response.status === 401) {
                // Handle unauthorized error
                alert('Your session has expired. Please sign in again.');
                window.location.href = '/login'; // Redirect to login page
                return;
            }
            throw new Error('Failed to fetch user profile');
        }

        const userProfile = await response.json(); // Parse the JSON from the response

        // Log the user profile and lab history for debugging
        console.log('Fetched user profile:', userProfile);
        if (userProfile.labHistory) {
            console.log('Lab history:', userProfile.labHistory);
        } else {
            console.log('No lab history found.');
        }

        return userProfile;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        return null;
    }
};

// Utility function to get session information from localStorage
export const useUserSession = () => {
    const idToken = localStorage.getItem('idToken');
    const sub = idToken ? jwtDecode(idToken).sub : null; // Decode sub if idToken is available

    return {
        idToken,
        sub,
    };
};
