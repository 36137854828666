// components/ChallengeLabs.js
import React, { useEffect, useState } from 'react';
import LabCard from './LabCard';
import { fetchUserProfile, useUserSession } from './sessionUtils';
import axios from 'axios';

// Static lab metadata
const staticLabMetadata = [
  {
    LabName: 's3-static-website',
    Title: 'Static Website in S3',
    Description: 'Troubleshoot issues with a static website hosted on Amazon S3.',
    EstimatedTime: 60,
    IsActive: true,
  },
  {
    LabName: 'nginx-challenge',
    Title: 'Nginx Challenge Lab',
    Description: 'Configure an Nginx web server and troubleshoot misconfigurations in the S3 bucket.',
    EstimatedTime: 165,
    IsActive: true,
  },
  {
    LabName: 'vpc-networking',
    Title: 'VPC Networking Lab',
    Description: 'Fix misconfigurations in the VPC setup, including subnets and security groups, to ensure proper networking.',
    EstimatedTime: 60,
    IsActive: true,
  },
  {
    LabName: 'ALB-Challenge',
    Title: 'ALB Challenge Lab',
    Description: 'Fix the misconfigurations in the AWS environment and make the site accessible through the Application Load Balancer.',
    EstimatedTime: 180,
    IsActive: true,
  },
];

const ChallengeLabs = ({ handleStartLab }) => {
  const [labs, setLabs] = useState([]);
  const [error, setError] = useState(null);
  const { idToken, sub } = useUserSession();

  // Fetch lab data and merge with static metadata
  useEffect(() => {
    const fetchLabs = async () => {
      try {
        const userProfile = await fetchUserProfile(sub, idToken);
        const userLabs = userProfile?.labHistory || [];

        // Merge static metadata with user lab history
        const mergedLabs = staticLabMetadata.map((staticLab) => {
          const userLabAttempts = userLabs.filter((lab) => lab.LabName === staticLab.LabName);
        
          // Find the latest Passed attempt, if any
          const passedAttempt = userLabAttempts.find((lab) => lab.Passed);
        
          // If no Passed attempt exists, find the latest attempt by Timestamp
          const latestAttempt = userLabAttempts.reduce(
            (latest, current) =>
              new Date(current.Timestamp) > new Date(latest.Timestamp) ? current : latest,
            { Timestamp: '1970-01-01T00:00:00.000Z' }
          );
        
          // Prioritize Passed attempt if available, otherwise fallback to the latest attempt
          const resolvedAttempt = passedAttempt || latestAttempt;
        
          return {
            ...staticLab,
            Status: resolvedAttempt.Status || 'Not Attempted',
            Passed: resolvedAttempt.Passed || false,
          };
        });
        
        setLabs(mergedLabs);
      } catch (err) {
        console.error('Error fetching labs:', err);
        setError('Unable to fetch lab information.');
      }
    };

    fetchLabs();
  }, [idToken, sub]);

  // Track lab start
  const handleStartLabWithTracking = async (labType, labName, labTitle, estimatedTime) => {
    const idToken = localStorage.getItem('idToken');
    const userSessionId = localStorage.getItem('userSessionId');
    const username = localStorage.getItem('username');
    const userId = userSessionId;

    try {
      await axios.post(
        process.env.REACT_APP_TRACK_LAB_START_API,
        {
          UserId: userId,
          UserSessionId: userSessionId,
          Username: username,
          LabName: labName,
          LabType: labType,
          Action: 'start_lab',
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (error) {
      console.error('Error tracking lab start:', error);
    }

    handleStartLab(labType, labName, labTitle, estimatedTime);
  };

  return (
    <div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {labs.map((lab, index) => (
        <LabCard
          key={index}
          title={lab.Title}
          description={lab.Description}
          buttonText="Start Challenge"
          handleClick={() =>
            handleStartLabWithTracking(
              'challenge',
              lab.LabName,
              lab.Title,
              lab.EstimatedTime
            )
          }
          isActive={lab.IsActive}
          status={lab.Status}
          passed={lab.Passed}
          labType="challenge" // Explicitly pass labType
        />
      ))}
    </div>
  );
};

export default ChallengeLabs;
