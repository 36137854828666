// components/LabCard.js
import React from 'react';
import { Row, Col, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'; // Added OverlayTrigger and Tooltip
import { CheckCircleFill, XCircleFill, Circle } from 'react-bootstrap-icons'; // Updated "Not Attempted" icon to use Circle

const LabCard = ({ title, description, buttonText, handleClick, isActive, status, passed }) => {
  // Determine the status icon and color with tooltip
  const getStatusIconWithTooltip = () => {
    let icon = null;
    let tooltipText = '';

    if (status === 'Completed') {
      if (passed) {
        icon = <CheckCircleFill style={{ color: '#28a745', fontSize: '2rem' }} />; // Green for Passed
        tooltipText = 'Completed Successfully';
      } else {
        icon = <XCircleFill style={{ color: '#dc3545', fontSize: '2rem' }} />; // Red for Failed
        tooltipText = 'Validations Not Met';
      }
    } else {
      icon = <Circle style={{ color: '#6c757d', fontSize: '2rem' }} />; // Gray hollow circle for Not Attempted
      tooltipText = 'Not Attempted';
    }

    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-${title}`}>{tooltipText}</Tooltip>}
      >
        <div>{icon}</div>
      </OverlayTrigger>
    );
  };

  return (
    <Row>
      <Col>
        <Card
          style={{
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            backgroundColor: isActive ? '#FFFFFF' : '#F0F0F0',
            opacity: isActive ? '1' : '0.7',
            padding: '1rem',
            marginBottom: '1rem',
            position: 'relative',
          }}
        >
          {/* Status Icon in the Top-Left */}
          <div
            style={{
              position: 'absolute',
              top: '10px',
              left: '10px',
            }}
          >
            {getStatusIconWithTooltip()}
          </div>

          <Card.Body>
            <Card.Title
              style={{
                color: '#E50A7A',
                fontWeight: 'bold',
                fontSize: '1.25rem',
              }}
            >
              {title}
            </Card.Title>
            <Card.Text
              style={{
                marginTop: '10px',
                marginBottom: '20px',
              }}
            >
              {description}
            </Card.Text>
            <Button
              onClick={handleClick}
              disabled={!isActive}
              style={{
                backgroundColor: isActive ? '#E50A7A' : '#C0C0C0',
                borderColor: isActive ? '#E50A7A' : '#C0C0C0',
                color: '#FFFFFF',
                width: '50%',
                margin: '0 auto',
                display: 'block',
              }}
            >
              {isActive ? buttonText : 'Coming Soon'}
            </Button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default LabCard;


